body {
    display: block;
    width: 80%;
    margin: 50px auto;
}
.privacy-container {
    display: flex;
    flex-direction: column;
}
.privacy-container p, .privacy-container h3, .privacy-container h4, .privacy-container address {
    text-align: justify;
}