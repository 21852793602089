@font-face {
  font-family: 'Rubik';
  src: local('Rubik'),
       url('./fonts/Rubik/Rubik-VariableFont_wght.ttf') format('truetype'),
       url('./fonts/Rubik/Rubik-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Sometype_Mono';
  src: local('Sometype_Mono'),
       url('./fonts/Sometype_Mono/SometypeMono-VariableFont_wght.ttf') format('truetype'),
       url('./fonts/Sometype_Mono/SometypeMono-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
       url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype'),
       url('./fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
