.d-flex{
    display: flex;
    flex-direction: row;
    flex-direction: row;
}
.justify-content-center{
    justify-content: center;
}
.main{
    display: flex;
    width: 100%;
    height: 150px;
    margin-top: 200px;
}
.my-20{
    margin: 50px 0px;
}
.link{
    color: #10B87D;
    font-weight: 500;
    margin: 20px 10px;
    text-decoration: none !important;
    cursor: pointer;
}
.link:hover{
    color: #3b1483;
}
.title{
    font-size: 26px;
    font-weight: 700;
}
.subtitle{
    font-weight: 500;
}
.regularText{
    width: 76%;
    margin-top: 8px;
    font-size: 14.5px !important;
    font-weight: 400;
}
.addToChrome{
    display: flex;
    flex-direction: row;
    justify-content: end;
    float: right;
    margin-top: 20px;
    margin-right: 190px;
}
.addToChrome_btn{
    display: flex;
    flex-direction: row;
    background-color: #10B87D;
    border: none;
    padding: 14px 20px;
    border-radius: 30px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    outline: none;
}
.addToChrome_btn:disabled{
    background-color: #bdbdbd;
    color: #fff;
    cursor: not-allowed;
}
.addToChrome_logo{
    height: 28px;
    padding: 0px 8px 0px 0px;
}
.imageProperties{
    width: 200px;
    float: right;
}
.logoSection{
    width: 40%;
}
.contentSection{
    display: flex;
    flex-direction: column;
}
.linksSection{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
