
.App {
  text-align: center;
  width:auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(13px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


input[type="file"] {
  outline: none;
  padding: 4px;
  margin: -4px;
}

input[type="file"]:focus-within::file-selector-button,
input[type="file"]:focus::file-selector-button {
  outline: 2px solid #0964b0;
  outline-offset: 2px;
}

input[type="file"]::before {
  top: 16px;
}

input[type="file"]::after {
  top: 14px;
}

/* ------- From Step 2 ------- */

input[type="file"] {
  position: relative;
}

input[type="file"]::file-selector-button {
  width: 136px;
  color: transparent;
}

/* Faked label styles and icon */
input[type="file"]::before {
  position: absolute;
  pointer-events: none;
  /*   top: 11px; */
  left: 40px;
  color: #FFF;
  font-weight:bold;
  font-size: 15px;
  content: "Upload File";
}

input[type="file"]::after {
  position: absolute;
  pointer-events: none;
  /*   top: 10px; */
  left: 16px;
  height:20px;
  width: 20px;
  content: "";
  color:#fff;
  background-image: url("../public/icons8-file.png");
}


/* ------- From Step 1 ------- */

/* file upload button */
input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  font-weight: bold;
  cursor: pointer;
  background-color: rgb(0,187,136);
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
  background-color:#0182E5;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}


.lottieContainer {
  width: 100%;
  display: flex;
  justify-content: center
}
.file-upload label {
  margin-right :  10px;
}

.footer {
  /* position:fixed; */
   left:0px;
   bottom:0px;
   height:30px;
   width:100%;
   margin-top: 20px;
}
.custom-select {
  max-width: 600px;
  position: relative;
  margin: 30px  0;
}

:root {
  --arrow-bg: rgb(0,187,136);
  --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
  --option-bg: #000;
  --select-bg: rgba(255, 255, 255, 0.2);
}

* {
  box-sizing: border-box;
}

body {
  display: grid;
  place-items: center;
  /* min-height: 100vh; */
  color: #000;
  width: 600px !important;
  height: 300px;
  /* background: linear-gradient(35deg, red, purple);background-image: linear-gradient(to bottom, #fb1e2b, #e2004f, #bc0067, #8e1572, #5c256f); */
}

/* <select> styles */
select {
  /* Reset */
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 20rem;
  padding: 1rem 4rem 1rem 1rem;
  background: var(--arrow-icon) no-repeat right 0.8em center/1.4em, linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
  color: #000;
  border-radius: 0.25em;
  border: 1px solid #D3D3D3;
  /* box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2); */
  cursor: pointer;
  /* Remove IE arrow */
  /* Remove focus outline */
  /* <option> colors */
}
select::-ms-expand {
  display: none;
}
select:focus {
  outline: none;
}
select option {
  color: inherit;
  background-color: var(--option-bg);
}
label{
  margin-right: 10px;
}
.fileUpload{
  display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    
}
.convert-container {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vW;
}
.input-label{
  font-size: 14px;
  font-weight: bold;
}
.input-container{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.label-container{
 width: 150px;
 text-align: left;
}
